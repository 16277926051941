.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timeline {
  position: absolute;
}
#cricle {
  position: relative;
  left: -160vh;
  top: 50%;

  height: 200vh;
  width: 200vh;

  border-radius: 50%;

  transform: translate(0%) rotate(100deg);
  border: 6px dotted #0b3d81;
}
#panel {
  position: relative;
  width: 5%;
  display: flex;
  left: -10vh;
  flex-direction: column;

  height: 20vh;

  padding: 4px;
  border-radius: 20%;
  align-items: center;
  justify-content: space-between;
}

.stripe {
  display: flex;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  height: 60px;

  transform-origin: 0% 50%;
}

.str1 {
  transform: translate(0%, -50%) rotate(-40deg);
}
.str2 {
  transform: translate(0%, -50%) rotate(-20deg);
}

.str4 {
  transform: translate(0%, -50%) rotate(20deg);
}
.str5 {
  transform: translate(0%, -50%) rotate(40deg);
}
.first {
  position: relative;
  width: 50%;
  height: 100%;
}
.smcicle {
  position: relative;

  left: 2vw;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  background-color: white;
  border: 20px solid #0b3d81;
}
.pointer {
  position: absolute;

  right: -1vw;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  background-color: white;
  border: 2px solid #0b3d81;
}
.dot {
  background-color: #0b3d81;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin: auto;
}
.year {
  padding-top: 45px;
  padding-bottom: 45px;
}
.sec {
  width: 50%;
  height: 100%;
}

@media (min-width: 1200px) {
 .smcicle{
  border: 20px solid #0b3d81;
 }
}

#mobilecricle {
  position: relative;
  top: -160vh;

  height: 200vh;
  width: 200vh;

  border-radius: 50%;

  transform: translate(0%) rotate(300deg);
  border: 6px dotted #0b3d81;
}

.mobilestripe {
  display: flex;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -100%);
  width: 100%;
  height: 60px;

  transform-origin: 0% 50%;
}

.mstr1 {
  transform: translate(0%, -50%) rotate(-40deg);
}
.mstr2 {
  transform: translate(0%, -50%) rotate(-20deg);
}

.mstr4 {
  transform: translate(0%, -50%) rotate(20deg);
}
.mstr5 {
  transform: translate(0%, -50%) rotate(40deg);
}
