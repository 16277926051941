@tailwind base;
@tailwind components;
@tailwind utilities;

.para {
  @apply text-[16px] max-lg:text-[15px] max-sm:text-[12px] font-jost leading-[145%]  tracking-[1px] max-lg:tracking-[0.75px] font-normal text-left text-[#383838];
}
.para_Home {
  @apply text-[16px] max-xl:text-[17px] max-lg:text-[15px] font-jost max-sm:text-[12px] leading-[145%]  tracking-[1px] max-lg:tracking-[0.75px] font-normal text-left  text-[#383838];
}

.heading {
  @apply text-[20px] max-lg:text-[15px]  leading-[145%]   max-sm:leading-[17px] tracking-[1px] max-lg:tracking-[0.75px] font-semibold text-left text-[#000];
}

.box-Shadow {
  box-shadow: 0px 0px 15px 0px rgba(9, 62, 134, 0.5);
}
.text-shadow:hover {
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.your-element {
  @apply ring-2 ring-blue-500;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset;

  /* For filter: drop-shadow */
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.bg-color {
  background: linear-gradient(
    130deg,
    #00000096 15.13%,
    rgba(47, 46, 46, 0) 81.42%
  );
}

.footerHeading {
  @apply text-[#0A3C81] text-[22px] max-lg:text-[17px] max-sm:text-[15px] font-normal  font-jost   tracking-[1px] max-lg:tracking-[0.75px] border-[#0A3C81] w-fit border-b-2  pb-1;
}

.footerlink {
  @apply text-[17px] max-lg:text-[15px] max-sm:text-[12px] font-jost leading-[145%]  tracking-[1px] max-lg:tracking-[0.75px] font-normal text-left text-[#909090];
}
